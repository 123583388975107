
// Initialize Firebase
// neptune-dev
var config = {
  apiKey: "AIzaSyC0ghunXE5dT1-gc0CBKiJuS8jQj_0Uux0",
  authDomain: "console.dev.smartcloud.smartsys.io",
  databaseURL: "https://sc-neptune-dev.firebaseio.com",
  projectId: "sc-neptune-dev",
  storageBucket: "sc-neptune-dev.appspot.com",
  messagingSenderId: "92658372676"
};


export { config }